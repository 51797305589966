import { createStore } from 'vuex'

const store = createStore({
    state () {
        return {}
    },
    getters: {},
    actions: {},
    mutations: {}
})

export default store;