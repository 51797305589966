import { v4 as uuidv4 } from 'uuid';

export const ANALYTICS_EVENT_TYPES = {
    PAGEVIEW: 'pageview',
    MODAL_OPEN: 'modal_open',
    MODAL_CLOSE: 'modal_close',
    VIDEO_PLAY: 'video_play',
    VIDEO_PAUSE: 'video_pause',
    VIDEO_RESTART: 'video_restart',
    VIDEO_MODAL_OPEN: 'video_modal_open',
    VIDEO_MODAL_CLOSE: 'video_modal_close',
    QUESTION_RESPONSE: 'question_response',
    SURVEY_FEEDBACK: 'survey_feedback',
};

class AnalyticsService {
    _sessionId;

    constructor() {
        this.sessionId = localStorage.getItem('sessionId') || uuidv4();
    }

    get sessionId() {
        return this._sessionId;
    }

    set sessionId(value) {
        this._sessionId = value;
        localStorage.setItem('sessionId', value);
    }

    initNewSession() {
        this.sessionId = uuidv4();
    }

    trackEvent(eventType, url, params = {}) {
        const event = {
            sessionId: this.sessionId,
            type: eventType,
            ...params,
            url: url,
            time: new Date().toISOString()
        }

        // log event to the server
        fetch(`${process.env.VUE_APP_SERVER_URL}/log-event`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(event)
        })
    }

    async getEvents() {
        const response = await fetch(`${process.env.VUE_APP_SERVER_URL}/events`);
        return await response.json();
    }

    getQuestionAnswersPercentage(questionId, events) {
        const questionResponses = events
            .filter(event => event.type === ANALYTICS_EVENT_TYPES.QUESTION_RESPONSE && event.questionId === questionId)
            .map(event => event.answer)

        // Count the occurrences of each answer
        const answerCounts = {};
        questionResponses.forEach(response => {
            var answers = response.split(';');
            answers.forEach(answer => {
                answerCounts[answer] = (answerCounts[answer] || 0) + 1;
            });
        });

        // Calculate the percentage for each answer
        const totalResponses = questionResponses.length;
        const answerPercentages = {};
        Object.keys(answerCounts).forEach(answer => {
            var count = answerCounts[answer];
            var percentage = (count / totalResponses) * 100;
            answerPercentages[answer] = Math.round(percentage.toFixed(2));
        });

        return answerPercentages;
    }

    async getResponsesPercentage(questionId) {
        const allEvents = await this.getEvents();

        return this.getQuestionAnswersPercentage(questionId, allEvents);
    }

    getResponsesPercentageSync(questionId, events) {
        return this.getQuestionAnswersPercentage(questionId, events);
    }
}

export default new AnalyticsService();