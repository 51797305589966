<template>
    <footer class="page-footer">
        <PopupModal modal-name="References" button-class="button page-footer__references-button m-5">
            <template #button>
                References
            </template>

            <slot>
                <div style="text-align: left;">
                    <h4>References:</h4>

                    <ol>
                        <li>Rajasekaran A, Julian BA, Rizk DV. IgA nephropathy: an interesting autoimmune kidney disease. Am J Med Sci. 2021;361(2):176-194</li>
                        <li>Pitcher, David; Braddon, Fiona; Hendry, Bruce; Mercer, Alex; Osmaston, Kate; Saleem, Moin A.; Steenkamp, Retha; Wong, Katie; Turner, A. Neil; Wang, Kaijun; Gale, Daniel P.; Barratt, Jonathan. Long-Term Outcomes in IgA Nephropathy. Clinical Journal of the American Society of Nephrology 18(6):p 727-738, June 2023. | DOI: 10.2215/CJN.0000000000000135</li>
                        <li>Rajasekaran A, Julian BA, Rizk DV. IgA nephropathy: an interesting autoimmune kidney disease. Am J Med Sci. 2021;361(2):176-194</li>
                        <li>Pitcher, David; Braddon, Fiona; Hendry, Bruce; Mercer, Alex; Osmaston, Kate; Saleem, Moin A.; Steenkamp, Retha; Wong, Katie; Turner, A. Neil; Wang, Kaijun; Gale, Daniel P.; Barratt, Jonathan. Long-Term Outcomes in IgA Nephropathy. Clinical Journal of the American Society of Nephrology 18(6):p 727-738, June 2023. | DOI: 10.2215/CJN.00000000000001351</li>
                        <li>Boyd JK, Cheung CK, Molyneux K, Feehally J, Barratt J. Kidney Int. 2012;81(9):833-843. 2. Kwon CS, Daniele P, Forsythe A, Ngai C. A systematic literature review of the epidemiology, health-related quality of life impact, and economic burden of immunoglobulin A nephropathy. J Health Econ Outcomes Res. 2021;8(2):36-45. 3. Rajasekaran A, Julian BA, Rizk DV. IgA nephropathy: an interesting autoimmune kidney disease. Am J Med Sci. 2021;361(2):176-194</li>
                    </ol>
                </div>
            </slot>
        </PopupModal>
    </footer>
</template>

<script>
export default {
    name: 'PageFooter',
}
</script>
