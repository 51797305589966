<template>
    <div class="start-screen">
        <div class="video-wrapper">
            <VideoPlayer
                video-name="videos/background_loop_final_new.mp4"
                :show-video-timings="false"
                is-bg-video
                loop-video
            />
        </div>

        <div class="start-screen__content">
            <h1 class="start-screen__sidebar__title">
                Test your knowledge and learn about IgA nephropathy
            </h1>

            <button class="button start-screen__start-button" @click="startSurvey">
                Start
            </button>

            <span class="start-screen__veeva-code">MED-US-IgAN-2400042</span>
        </div>

        <img class="start-screen__calliditas-logo" src="@/assets/images/calliditas-logo.svg" alt="calliditas logo" />
    </div>
</template>

<script setup>
import AnalyticsService, { ANALYTICS_EVENT_TYPES } from '@/services/analytics';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

function startSurvey() {
    // init new session and track pageview
    AnalyticsService.initNewSession();
    AnalyticsService.trackEvent(ANALYTICS_EVENT_TYPES.PAGEVIEW, route.fullPath);

    router.push({ path: `/question/${0}` });
}
</script>
