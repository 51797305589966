<template>
    <header class="page-header">
        <button class="page-header__restart-button" @click="restart">
            <span style="margin: 0.5rem;">Restart</span> <img
                width="20"
                height="20"
                src="@/assets/images/refresh-white.svg"
                alt="refresh white icon"
            />
        </button>

        <img class="page-header__iganexus-logo" src="@/assets/images/iganexus-logo.png" alt="iganexus logo" />
    </header>
</template>

<script>
export default {
    name: 'PageHeader',
    methods: {
        restart() {
            this.$router.push({ name: 'StartScreen' });
        }
    }
}
</script>
