import { createRouter, createWebHashHistory } from 'vue-router';
import PresentationLauncher from '@/launcher/PresentationLauncher';
import DispatcherStartScreen from '@/pages/dispatcher/StartScreen';
import DispatcherQuestionScreen from '@/pages/dispatcher/QuestionScreen';
import DispatcherSummaryScreen from '@/pages/dispatcher/SummaryScreen';
// import ReceiverStartScreen from '@/pages/receiver/StartScreen';
// import ReceiverQuestionScreen from '@/pages/receiver/QuestionScreen';
import ReceiverQuestionReviewScreen from '@/pages/receiver/QuestionReviewScreen';
// import ReceiverSummaryScreen from '@/pages/receiver/SummaryScreen';

import ErrorPage from '@/pages/ErrorPage';
import NotFound from '@/pages/NotFound';
import AnalyticsPage from '@/pages/AnalyticsPage';

const showLauncherPage = false;

const baseRoutes = [
    ...(showLauncherPage
        ? [
            {
                path: '/',
                name: 'PresentationLauncher',
                component: PresentationLauncher,
                meta: {
                    showNavigation: true,
                    showHeader: false,
                    showFooter: false,
                    presentationLauncher: true
                }
            },
            {
                path: '/start',
                name: 'StartScreen',
                component: DispatcherStartScreen,
                meta: {
                    showNavigation: false,
                    showHeader: false,
                    showFooter: false
                }
            }
        ]
        : [
            {
                path: '/',
                name: 'StartScreen',
                component: DispatcherStartScreen,
                meta: {
                    showNavigation: false,
                    showHeader: false,
                    showFooter: false
                }
            }
        ]),
    {
        path: '/question/:questionIndex',
        name: 'QuestionScreen',
        component: DispatcherQuestionScreen,
        meta: {
            showNavigation: false,
            showHeader: true,
            showFooter: true
        }
    },
    {
        path: '/question-review/:questionIndex',
        name: 'QuestionReviewScreen',
        component: ReceiverQuestionReviewScreen,
        meta: {
            showNavigation: false,
            showHeader: true,
            showFooter: true
        }
    },
    {
        path: '/summary',
        name: 'SummaryScreen',
        component: DispatcherSummaryScreen,
        meta: {
            showNavigation: false,
            showHeader: true,
            showFooter: false
        }
    },
    {
        path: '/analytics',
        name: 'AnalyticsPage',
        component: AnalyticsPage,
        meta: {
            presentationLauncher: true,
            requireAuth: true
        }
    },
    {
        path: '/error',
        name: 'ErrorPage',
        component: ErrorPage
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes: baseRoutes,

    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.requireAuth) {
        const password = prompt('Please enter the password to access this page:');

        if (password) {
            try {
                const response = await fetch(`${process.env.VUE_APP_SERVER_URL}/auth`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ password }),
                });

                const data = await response.json();

                if (data.valid) {
                    next(); // allow access to the page
                } else {
                    alert('Incorrect password. Access denied.');
                    next(false); // prevent navigation
                }
            } catch (error) {
                console.error('Error validating password:', error);
                alert('Error validating password. Please try again later.');
                next(false);
            }
        } else {
            next(false); // cancel navigation if no password is entered
        }
    } else {
        next(); // allow access to non-protected pages
    }
});
export default router;
