<template>
    <div class="survey-question">
        <QuestionTitle :title="question.title" :tag="question.tag" />

        <h4 class="question-instructions">
            {{ higlightCorrect ? question.instructionsOnSubmit || question.instructions : question.instructions }}
        </h4>

        <div v-if="question.type === QUESTION_TYPES.SINGLE_SELECT" class="question" :class="{'single-select-q4': question.id === 4, 'single-select': question.id !== 4}">
            <span
                v-for="(option, index) in question.options"
                :key="option"
                :class="[
                    'single-select__item',
                    higlightCorrect || readonly && 'single-select__item--readonly',
                    higlightCorrect
                        ? question.showPercentageOnSubmit
                            ? getSingleSelectPercentage(option)
                            : singleSelectHighlightClass(option)
                        : answer === option && 'single-select__item--selected',
                ]"
                @click="setAnswer(option)"
            >
                <span class="item-letter">{{ optionLetter(index) }}</span>
                {{ option }}<span></span></span>
        </div>

        <div v-if="question.type === QUESTION_TYPES.MULTI_SELECT" class="question multi-select">
            <span
                v-for="option in question.options"
                :key="option"
                :class="[
                    'multi-select__item',
                    higlightCorrect || readonly && 'multi-select__item--readonly',
                    higlightCorrect
                        ? question.showPercentageOnSubmit
                            ? getMultiSelectPercentage(option)
                            : multiSelectHighlightClass(option)
                        : Array.isArray(answer) && answer.includes(option) && 'multi-select__item--selected',
                ]"
                @click="setAnswer(option)"
            >{{ option }}</span>
        </div>

        <div v-if="question.type === QUESTION_TYPES.RANKED" class="question ranked">
            <div class="ranked-list">
                <draggable
                    v-for="listIndex in question.options.length"
                    :key="listIndex"
                    v-model="rankedListLeft[listIndex - 1]"
                    class="ranked-list__drop-zone"
                    :class="[isDropZoneFull(listIndex - 1) && 'ranked-list__drop-zone--full']"
                    ghost-class="ranked-list__item--ghost"
                    chosen-class="ranked-list__item--chosen"
                    :disabled="readonly || higlightCorrect"
                    :sort="false"
                    :item-key="(o) => o"
                    group="options"
                    @change="(event) => handleRankedListChange(event, listIndex - 1)"
                >
                    <template v-if="!isDropZoneFull(listIndex - 1)" #header>
                        <span class="item-letter">{{ optionLetter(listIndex - 1) }}</span>
                    </template>

                    <template #item="{ element }">
                        <div
                            :class="[
                                'ranked-list__item',
                                higlightCorrect || readonly && 'ranked-list__item--readonly',
                                higlightCorrect
                                    ? rankedHighlightClass(listIndex - 1)
                                    : ''
                            ]"
                        >
                            <span class="item-letter">{{ optionLetter(listIndex-1) }}</span>
                            {{ element }}
                            <span></span>
                        </div>
                    </template>
                </draggable>
            </div>

            <div class="ranked__dragdrop-image__wrapper">
                <img
                    v-if="!isDirty"
                    class="ranked__dragdrop-image"
                    src="@/assets/images/drag-and-drop.gif"
                    alt="Drag Drop hand"
                />
            </div>
            

            <draggable
                v-model="rankedListRight"
                class="ranked-list"
                :disabled="readonly || higlightCorrect"
                :sort="false"
                :item-key="(o) => o"
                group="options"
            >
                <template #item="{ element }">
                    <div class="ranked-list__item" :class="[higlightCorrect && 'ranked-list__item--correct']">
                        {{ element }}
                    </div>
                </template>
            </draggable>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { QUESTION_TYPES } from '@/questions';
import QuestionTitle from '@/components/questions/QuestionTitle.vue';
import AnalyticsService from '@/services/analytics';

const LETTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

export default {
    name: 'SurveyQuestion',
    components: {
        draggable,
        QuestionTitle
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
        modelValue: {
            type: [String, Array],
            default: ''
        },
        higlightCorrect: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            QUESTION_TYPES,
            rankedListLeft: this.question.options.map(() => []),
            rankedListRight: this.question.options,
            percentages: {}
        }
    },
    computed: {
        optionLetter() {
            return (index) => LETTERS[index];
        },
        answer: {
            get() {
                return this.modelValue;
            },
            set(value) {
                if (this.readonly) {
                    return;
                }

                if (this.question.type === QUESTION_TYPES.SINGLE_SELECT) {
                    this.$emit('update:modelValue', value);
                } else if (this.question.type === QUESTION_TYPES.MULTI_SELECT) {
                    if (!Array.isArray(this.answer)) {
                        this.$emit('update:modelValue', [value]);
                    } else if (this.answer.includes(value)) {
                        this.$emit('update:modelValue', this.answer.filter(o => o !== value));
                    } else {
                        this.$emit('update:modelValue', [...this.answer, value]);
                    }
                } else if (this.question.type === QUESTION_TYPES.RANKED) {
                    this.$emit('update:modelValue', value);
                }
            }
        },
        isDirty() {
            return Array.isArray(this.answer) ? this.answer.some(o => !!o) : !!this.answer;
        },
        isDropZoneFull() {
            return (index) => {
                return this.rankedListLeft[index].length;
            }
        },
        singleSelectHighlightClass() {
            return (option) => {
                if (this.question.answer) {
                    return this.answer === option || option === this.question.answer
                        ? option === this.question.answer
                            ? 'single-select__item--correct'
                            : 'single-select__item--incorrect'
                        : '';
                } else {
                    return option === this.answer ? 'single-select__item--correct' : '';
                }
            }
        },
        multiSelectHighlightClass() {
            return (option) => {
                if (this.question.answer) {
                    return (Array.isArray(this.answer) && this.answer.includes(option)) || this.question.answer.includes(option)
                        ? this.question.answer.includes(option)
                            ? 'multi-select__item--correct'
                            : 'multi-select__item--incorrect'
                        : ''
                } else {
                    return Array.isArray(this.answer) && this.answer.includes(option) ? 'single-select__item--correct' : '';
                }
            }
        },
        rankedHighlightClass() {
            return (index) => {
                if (this.question.answer) {
                    return this.answer[index] === this.question.answer[index]
                        ? 'ranked-list__item--correct'
                        : 'ranked-list__item--incorrect'
                } else {
                    return 'ranked-list__item--correct';
                }
            }
        },
        getSingleSelectPercentage() {
            return (option) => {
                return `multi-select__item--percentage-${this.percentages[option] || 0}`;
            }
        },
        getMultiSelectPercentage() {
            return (option) => {
                return `multi-select__item--percentage-${this.percentages[option] || 0}`;
            }
        }
    },
    watch: {
        question: {
            handler() {
                this.rankedListLeft = this.question.options.map(() => []);
                this.rankedListRight = this.question.options;
            },
            immediate: true
        },
        async higlightCorrect(newValue) {
            if (newValue) {
                this.rankedListRight = this.question.answer;

                if (this.question.showPercentageOnSubmit) {
                    // Fetch percentages
                    this.percentages = await AnalyticsService.getResponsesPercentage(this.question.id);
                }
            }
        }  
    },
    methods: {
        setAnswer(value) {
            if (this.readonly || this.higlightCorrect) {
                return;
            }

            this.answer = value;
        },
        handleRankedListChange(event, dropListIndex) {
            if (event.added) {
                if (this.rankedListLeft[dropListIndex].length > 1) {
                    // if we have already item in this drop list move it back to the right list
                    const elementToRemove = this.rankedListLeft[dropListIndex].find(el => el !== event.added.element);
                    this.rankedListRight.push(elementToRemove);
                    this.rankedListLeft[dropListIndex] = [event.added.element];
                } else {
                    this.rankedListLeft[dropListIndex] = [event.added.element];
                }
            }

            this.setAnswer(this.rankedListLeft.map(list => list[0]));
        },
    }
}
</script>