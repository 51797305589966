<template>
    <div class="summary-screen--dispatcher">
        <h1 class="summary-screen__header">
            Thank you for interacting with this experience
        </h1>
            
        <p v-if="rating" class="summary-screen__feedback-thankyou">
            Thank you for you feedback!
        </p>

        <template v-else>
            <div class="summary-screen__feedback-wrapper">
                <p>Was this information useful for you?</p>

                <div class="summary-screen__feedback">
                    <div class="summary-screen__feedback-star-container" @click="rate(1)">
                        <img class="summary-screen__feedback-star" src="@/assets/images/star.svg" alt="star" />
                        <span class="summary-screen__feedback-star-text">Not at all useful</span>
                    </div>

                    <div class="summary-screen__feedback-star-container" @click="rate(2)">
                        <img class="summary-screen__feedback-star" src="@/assets/images/star.svg" alt="star" />
                    </div>

                    <div class="summary-screen__feedback-star-container" @click="rate(3)">
                        <img class="summary-screen__feedback-star" src="@/assets/images/star.svg" alt="star" />
                    </div>

                    <div class="summary-screen__feedback-star-container" @click="rate(4)">
                        <img class="summary-screen__feedback-star" src="@/assets/images/star.svg" alt="star" />
                    </div>

                    <div class="summary-screen__feedback-star-container" @click="rate(5)">
                        <img class="summary-screen__feedback-star" src="@/assets/images/star.svg" alt="star" />
                        <span class="summary-screen__feedback-star-text">Very useful</span>
                    </div>
                </div>
            </div>
        </template>

        <button class="button summary-screen__finish-button" @click="finishSurvey">
            Finish
        </button>

        <span class="summary-screen__veeva-code">MED-US-IgAN-2400042</span>
    </div>
</template>

<script>
import AnalyticsService, { ANALYTICS_EVENT_TYPES } from '@/services/analytics';

export default {
    name: 'SummaryScreen',
    data() {
        return {
            rating: 0
        };
    },
    methods: {
        finishSurvey() {
            this.$router.push({ name: 'StartScreen' });
        },
        rate(rating) {
            this.rating = rating;

            AnalyticsService.trackEvent(ANALYTICS_EVENT_TYPES.SURVEY_FEEDBACK, this.$route.path, {
                rating: this.rating,
            });
        }
    }
}
</script>