<template>
    <div class="question-screen">
        <SurveyPagination :count="questionCount" :current="questionIndex" />

        <SurveyQuestion
            v-model="answer"
            :question="currentQuestion"
            :higlight-correct="isSubmitted"
        />

        <div class="question-screen__footer">
            <div class="question-screen__actions">
                <button v-if="isTouched && !isSubmitted" class="button question-screen__submit-button" @click="submitAnswer">
                    Submit
                </button>

                <template v-if="showNextBtn">
                    <button v-if="currentQuestion.showPercentageOnSubmit" class="button question-screen__next-button" @click="goToNextQuestion">
                        <span style="margin-right: 0.5rem;">Next Question</span> <img
                            width="20"
                            height="20"
                            src="@/assets/images/arrow-right.svg"
                            alt="arrow right icon"
                        />
                    </button>

                    <button v-else class="button question-screen__next-button" @click="goToNextQuestion">
                        <span style="margin-right: 0.5rem;">See Explanation</span> <img
                            width="20"
                            height="20"
                            src="@/assets/images/arrow-right.svg"
                            alt="arrow right icon"
                        />
                    </button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { QUESTION_TYPES, questions } from '@/questions';
import SurveyQuestion from '@/components/questions/SurveyQuestion.vue';
import SurveyPagination from '@/components/SurveyPagination.vue';
import AnalyticsService, { ANALYTICS_EVENT_TYPES } from '@/services/analytics';

export default {
    name: 'QuestionScreen',
    components: {
        SurveyQuestion,
        SurveyPagination,
    },
    data() {
        return {
            isSubmitted: false,
            showNextBtn: false,
            answer: null,
        };
    },
    computed: {
        isTouched() {
            switch(this.currentQuestion.type) {
            case QUESTION_TYPES.SINGLE_SELECT:
                return this.answer;
            case QUESTION_TYPES.MULTI_SELECT:
                return Array.isArray(this.answer) && this.answer.length;
            case QUESTION_TYPES.RANKED:
                return Array.isArray(this.answer) && this.answer.every((o) => o);
            default:
                return false;
            }
        },
        questionCount() {
            return questions.length;
        },
        questionIndex() {
            return parseInt(this.$route.params.questionIndex) || 0;
        },
        currentQuestion() {
            return questions[this.questionIndex];
        },
        isLastQuestion() {
            return this.questionIndex === questions.length - 1;
        },
    },
    watch: {
        questionIndex: {
            handler() {
                this.answer = null;
                this.isSubmitted = false;
                this.showNextBtn = false;
            },
            immediate: true
        }
    },
    methods: {
        submitAnswer() {
            const NEXT_BUTTON_SHOW_DELAY = 0;

            AnalyticsService.trackEvent(ANALYTICS_EVENT_TYPES.QUESTION_RESPONSE, this.$route.path, {
                questionId: this.currentQuestion.id,
                questionTitle: this.currentQuestion.title,
                answer: Array.isArray(this.answer) ? this.answer.join(';') : this.answer,
                correct: !this.currentQuestion.answer
                    ? ''
                    : Array.isArray(this.answer)
                        ? this.answer.every((item, index) => item === this.currentQuestion.answer[index])
                        : this.answer === this.currentQuestion.answer,
            });

            this.isSubmitted = true;

            // show next button with a delay
            setTimeout(() => {
                this.showNextBtn = true;
            }, NEXT_BUTTON_SHOW_DELAY)
        },
        goToNextQuestion() {
            this.answer = null;

            if (this.currentQuestion.showPercentageOnSubmit) {
                this.$router.push({ path: `/question/${this.questionIndex + 1}` });
            } else {
                this.$router.push({ path: `/question-review/${this.questionIndex}` });
            }
        }
    }
}
</script>