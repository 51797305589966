<template>
    <div>
        <metainfo>
            <template #title>
                {{ `Calliditas IgANexus Survey` }}
            </template>
        </metainfo>

        <IconSet />

        <div
            v-if="$route.meta.presentationLauncher"
            class="layout layout--launcher"
        >
            <router-view />
        </div>

        <div v-else class="layout layout--full-screen" :class="$route.name">
            <PageHeader v-if="showHeader" />
            <div v-else></div> 

            <main class="main">
                <router-view />

                <SecretButton v-if="showLauncherPage" position="top-right" />
            </main>

            <NavigationList v-if="showNavigation" />
            
            <PageFooter v-if="showFooter" />
            <div v-else></div>

            <TimeoutModal v-if="showTimeoutModal" />
        </div>
    </div>
</template>

<script>
import SecretButton from '@/launcher/SecretButton.vue';
import IconSet from '@/components/IconSet.vue';
import PageHeader from '@/components/PageHeader.vue';
import PageFooter from '@/components/PageFooter.vue';
import NavigationList from '@/components/NavigationList';
import AnalyticsService, { ANALYTICS_EVENT_TYPES } from '@/services/analytics';
import { useMeta } from 'vue-meta';

export default {
    name: 'App',

    components: {
        SecretButton,
        IconSet,
        PageHeader,
        PageFooter,
        NavigationList
    },

    setup() {
        useMeta({
            title: 'Welcome'
        });
    },

    data() {
        return {
            showLauncherPage: false,
        };
    },

    computed: {
        showTimeoutModal() {
            return process.env.VUE_APP_TIMEOUT_VALUE && parseInt(process.env.VUE_APP_TIMEOUT_VALUE) > 0;
        },
        showNavigation() {
            return this.$route.meta.showNavigation;
        },
        showHeader() {
            return this.$route.meta.showHeader;
        },
        showFooter() {
            return this.$route.meta.showFooter;
        },
    },

    watch: {
        $route: {
            deep: true,
            handler(to) {
                console.log('Route changed', to.name);
                // New session is initiated when use clicks Start button on the StartScreen
                if (!['StartScreen', 'PresentationLauncher', 'AnalyticsPage', 'ErrorPage'].includes(to.name)) {
                    // If we aren't on the launcher screen or analytics screen, update congressStorageData with the page view
                    AnalyticsService.trackEvent(ANALYTICS_EVENT_TYPES.PAGEVIEW, to.fullPath);
                }
            }
        }
    },
};
</script>

<style lang="scss">
@import './src/scss/app';
</style>
