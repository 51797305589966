<template>
    <div class="question-review-screen">
        <div class="question-review-screen__video-wrapper">
            <h1 class="question-review-screen__title">
                <span v-if="currentQuestion.tag" class="question-tag__wrapper">
                    <span class="question-tag">{{ currentQuestion.tag }}</span>
                </span>
                <span v-html="currentQuestion.info.title"></span>
            </h1>
            <h2
                v-if="currentQuestion.info.subtitle"
                class="question-review-screen__subtitle"
            >
                <span v-html="currentQuestion.info.subtitle"></span>
            </h2>

            <div v-if="questionIndex === 4" class="image-container">
                <img src="@/assets/images/ASN-image1.png" alt="Image 1" class="image-item" />
                <img src="@/assets/images/ASN-image2.png" alt="Image 2" class="image-item" />
            </div>

            <VideoPlayer
                v-else-if="currentQuestion.info.videoPath"
                class="question-review-screen__video"
                :video-name="currentQuestion.info.videoPath"
                :is-video-muted="false"
                :show-video-timings="false"
                :controls="true" 
                :video-restart-reader="restartReader"
            />
            
            <p v-if="currentQuestion.info.videoCaption" class="question-review-screen__video-caption">
                {{ currentQuestion.info.videoCaption }}
            </p>
        </div>

        <div class="qrcode-wrapper">
            <div v-if="currentQuestion.info.qrCodeText" class="qrcode-text" v-html="currentQuestion.info.qrCodeText">
            </div>

            <div v-if="currentQuestion.info.qrCodePath" class="qrcode-container">
                <a href="https://www.iganexus.com/" target="_blank"><div class="qrcode">
                    <p class="qrcode-call-to-action-text">
                        Visit the IgANexus Website to learn more
                    </p>
                </div></a>
            </div>
        </div>

        <div class="question-screen__footer">
            <div class="question-screen__actions footer-actions">
                <template v-if="showNextBtn">
                    <button v-if="questionIndex !== 4" class="button question-screen__restart-video-button" @click="restartVideo">
                        <img
                            width="20"
                            height="20"
                            src="@/assets/images/refresh-white.svg"
                            alt="refresh white icon"
                        />
                        <span style="margin-left: 0.5rem;">Restart Video</span>
                    </button>
        
                    <button class="button question-screen__next-button" @click="goToNextQuestion">
                        <span style="margin-right: 0.5rem;">{{ isLastQuestion ? 'Finish Quiz' : 'Next Question' }}</span> <img
                            width="20"
                            height="20"
                            src="@/assets/images/arrow-right.svg"
                            alt="arrow right icon"
                        />
                    </button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { questions } from '@/questions';

export default {
    name: 'QuestionReviewScreen',
    data() {
        return {
            answer: null,
            readonly: false,
            showNextBtn: false,
            restart: 0,
        }
    },
    computed: {
        questionIndex() {
            return parseInt(this.$route.params.questionIndex) || 0;
        },
        currentQuestion() {
            return questions[this.questionIndex];
        },
        restartReader() {
            return this.restart;
        },
        isLastQuestion() {
            return parseInt(this.questionIndex) === questions.length - 1;
        },
    },
    mounted() {
        const NEXT_BUTTON_SHOW_DELAY = 0;
        // show next button with a delay
        setTimeout(() => {
            this.showNextBtn = true;
        }, NEXT_BUTTON_SHOW_DELAY)
    },
    methods: {
        goToNextQuestion() {
            this.answer = null;

            if (this.isLastQuestion) {
                this.$router.push({ name: 'SummaryScreen' });
                return;
            }

            this.$router.push({ path: `/question/${this.questionIndex + 1}` });

        },
        restartVideo() {
            this.restart++;
        },
    }
}
</script>

<style lang="css" scoped>
.image-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.image-item {
    width: 49%;
    object-fit: cover;
}

.footer-actions {
    padding-top: 10vh;
}
</style>
